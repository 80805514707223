@-webkit-keyframes fadeIn { from {opacity:0;} to {opacity:1;} }
@-moz-keyframes fadeIn { from {opacity:0;} to {opacity:1;} }
@keyframes fadeIn { from {opacity:0;} to {opacity:1;} }

body {
  margin: 0;
  padding: 0;
  background-color: #e9ecef !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  overflow-x: hidden !important;
  -webkit-text-stroke: 0.45px;
}

.fade-in{
	opacity: 0;
	-webkit-animation:fadeIn ease-in 1;
	-moz-animation:fadeIn ease-in 1;
	animation: fadeIn ease-in 1;

	-webkit-animation-fill-mode: forwards;
	-moz-animation-fill-mode: forwards;
	animation-fill-mode: forwards;

	-webkit-animation-duration: 2s;
	-moz-animation-duration: 2s;
	animation-duration: 2s;
}

.fade-in.one{
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	animation-delay: 1s;
}

.fade-in.two{
	-webkit-animation-delay: 1.1s;
	-moz-animation-delay: 1.1s;
	animation-delay: 1.1s;
}

.fade-in.three{
	-webkit-animation-delay: 1.2s;
	-moz-animation-delay: 1.2s;
	animation-delay: 1.2s;
}
.fade-in.four{
	-webkit-animation-delay: 1.3s;
	-moz-animation-delay: 1.3s;
	animation-delay: 1.3s;
}
.fade-in.five{
	-webkit-animation-delay: 1.4s;
	-moz-animation-delay: 1.4s;
	animation-delay: 1.4s;
}
.fade-in.six{
	-webkit-animation-delay: 1.5s;
	-moz-animation-delay: 1.5s;
	animation-delay: 1.5s;
}
.fade-in.seven{
	-webkit-animation-delay: 1.6s;
	-moz-animation-delay: 1.6s;
	animation-delay: 1.6s;
}
.fade-in.eight{
	-webkit-animation-delay: 1.7s;
	-moz-animation-delay: 1.7s;
	animation-delay: 1.7s;
}
.fade-in.nine{
	-webkit-animation-delay: 1.8s;
	-moz-animation-delay: 1.8s;
	animation-delay: 1.8s;
}
.fade-in.ten{
	-webkit-animation-delay: 1.9s;
	-moz-animation-delay: 1.9s;
	animation-delay: 1.9s;
}

.zoom{
  transition: all .75s ease-in-out;
  -webkit-transition: all .75s ease-in-out; /* Safari */
}
.zoom:hover{
  transform: scale(1.13);
  transition-timing-function: ease-in;
  webkit-transition-timing-function: ease-in;
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .mock-wrapper{
      height: auto;
      width: 290px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Portrait */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .mock-wrapper{
      height: auto;
      width: 290px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .mock-wrapper{
      height: auto;
      width: 290px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Portrait */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
      margin: 0px !important;
    }
    .campaign-project-overview{
      text-shadow: #000 0px 1px 1px;
      padding: 0em !important;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 2.5em;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-top: 7em;
      text-shadow: #000 0px 1px 1px;
      color: #000000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
      margin: 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
    }
    .campaign-project-overview{
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 0em !important;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-shadow: #000 0px 1px 1px;
      color: #000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {
    .mock-wrapper{
      height: auto;
      width: 290px;
      padding: 2em 0em 2em 0em;
      margin: 0em;
    }
    .col-md-6{
      padding: 0em !important;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
    }
    .campaign-project-overview{
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 0em !important;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-shadow: #000 0px 1px 1px;
      color: #000 !important;
    }
    .row{
      margin: 0em !important;
    }
}

/* Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {
    .mock-wrapper{
      height: auto;
      width: 350px;
      padding: 2em 0em 2em 0em;
    }
    .reactblog-section-wrapper{
      padding: 8.75em 0em 8.75em 0em;
      margin-left: 0em !important;
    }
    .reactblog-project-overview{
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .campaign-section-wrapper{
      margin-left: 0em !important;
      padding: 8.5em 0em 8.5em 0em;
    }
    .campaign-project-overview{
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-shadow: #000 0px 1px 1px;
      color: #fff !important;
    }
    .smallproject-section-wrapper{
      margin-left: 0em !important;
      padding: 8.75em 0em 8.75em 0em;
    }
    .smallproject-project-overview{
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-shadow: #000 0px 1px 1px;
      color: #000 !important;
    }
    .row{
      margin: 0em !important;
    }
}
