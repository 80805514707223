#AlumniProject{
  background: linear-gradient(to bottom left, #da534f, #d02339c7);
}
.alumni-section-wrapper{
  margin-right: 2.5em;
  padding: 11.75em 0em 11.75em 0em;
}
.alumni-project-overview{
  text-shadow: #000 0px 1px 1px;
  color: #fff !important;
}
