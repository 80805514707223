#CampaignProject{
  background: linear-gradient(to bottom right, #563d7c, #45296f);
}
.campaign-section-wrapper{
  margin-left: 2.5em;
  padding: 8.5em 0em 8.5em 0em;
}
.campaign-project-overview{
  text-shadow: #000 0px 1px 1px;
  color: #fff !important;
}
