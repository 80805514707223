#LinuxSetupProject{
  background: linear-gradient(to bottom left, #f0dc4f, #f5de3c);
}
.Linux-section-wrapper{
  margin-left: 2.5em;
  padding: 11.75em 2em 11.75em 0em;
}
.Linux-project-overview{
  text-shadow: #000 0px 1px 1px;
  color: #000 !important;
}

.img-border{
  box-shadow: 0px 18px 24px black;
  margin-bottom: 3em;
}
