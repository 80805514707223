#Footer{
  width: 100%;
  margin: 0;
}
.footer-wrapper{
  height: auto !important;
  background: -webkit-linear-gradient(top left, #171819c2, #0e0f10c2);
  background: -o-linear-gradient(top left, #171819c2, #0e0f10c2);
  background: linear-gradient(to bottom right, #171819c2, #0e0f10c2);
}
.footer-content{
  padding: .45em;
  color: #ffffff;
}
