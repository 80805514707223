#SmallProjects{
  background: linear-gradient(to bottom right, #ffffff85, #d3d3d3);
}
.smallproject-section-wrapper{
  margin-left: 2.5em;
  padding: 8.75em 0em 8.75em 0em;
}
.smallproject-project-overview{
  padding-top: 7em;
  text-shadow: #fff 0px 1px 1px;
  color: #000000 !important;
}
.zoom-quicker{
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out; /* Safari */
}
.zoom-quicker:hover{
  transform: scale(1.075);
  transition-timing-function: ease-in;
  webkit-transition-timing-function: ease-in;
}

.smallproject-thumbnail{
  width: inherit;
  height: 11em;
  border-radius: 5% 5% 0% 0%;
}
.smallproject-info{
   padding: 1em;
}

.scrolling-wrapper {
  padding: 2em 2em 2em 0.5em;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.scrolling-wrapper .smallproject-item {
  display: inline-block;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.scrolling-wrapper-flexbox .smallproject-item {
  flex: 0 0 auto;
  margin-right: 3px;
}

.smallproject-item{
  z-index: 100;
  width: 20em;
  height: 25em;
  background: linear-gradient(to bottom right, #ffffff, #ffffff45);
  border-radius: 5%;
  box-shadow: 0px 0px 25px #000000;
  margin: 0em 5em 0em 2.5em;
  white-space: pre-line !important;
}

.scrolling-wrapper, .scrolling-wrapper-flexbox {
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.scrolling-wrapper::-webkit-scrollbar, .scrolling-wrapper-flexbox::-webkit-scrollbar {
  display: none;
}
.blur-effect{
  background-color: #ffffff05;
}

.smallProject-anchor{
  color: unset;
  text-decoration: none;
}
.smallProject-anchor:hover{
  color: unset;
}
