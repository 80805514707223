#Landing{
  overflow-x: auto;
  height: 100vh !important;
  background: linear-gradient(to bottom right, #349, #149);
}
.landing-icon{
  margin: 0em .3em 0em .3em;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}
.landing-title{
  margin-top: 15em;
}
.home-title{
  color: #ffffff;
}
