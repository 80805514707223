.l{
  display: block !important;
}
.nav-lists{
  display: block !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
