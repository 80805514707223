#About{
  background: #f9f9f9;
  padding: 0em 0em 5em 0em;
}
.about-icons-text{
  text-align: center;
}
.about-section-wrapper{
  padding: 5em 0em 5em 0em;
}
.about-section-wrapper > p{
  font-size: 14pt;
}
.about-icons{
  opacity: 1.0;
  transition: all .75s ease-in-out;
  -webkit-transition: all .75s ease-in-out; /* Safari */
}
.about-icons > p{
  opacity: 0;
  visibility: hidden !important;
  transition: all .75s ease-in-out;
  -webkit-transition: all .75s ease-in-out; /* Safari */
}
.about-icons:hover > p{
  opacity: 1;
  visibility: visible !important;
}
.about-icons:hover{
  cursor: pointer;
  opacity: .65;
  transform: scale(1.1);
  transition-timing-function: ease-in;
  webkit-transition-timing-function: ease-in;
}
