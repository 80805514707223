#ReactBlogProject{
  background: linear-gradient(to bottom right, #51fdc4, #51fd94);
}
.reactblog-section-wrapper{
  margin-left: 2.5em;
  padding: 8.75em 0em 8.75em 0em;
}
.reactblog-project-overview{
  padding-top: 7em;
  text-shadow: #000 0px 1px 1px;
  color: #fff !important;
}
