#TodolistProject{
  background: linear-gradient(to bottom right, #a1c9f7, #8daadb);
}
.todo-section-wrapper{
  margin-right: 2.5em;
  padding: 8.75em 0em 8.75em 0em;
}
.todo-project-overview{
  padding-top: 7em;
  text-shadow: #000 0px 1px 1px;
  color: #fff !important;
}
